<template>
    <Layout>
      <template v-if="this.$route.name == 'add-digiMr'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row" id="case-carousel">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum" @submit.prevent="this.$route.name == 'add-digiMr' ? 'Save Data' : 'Update Data'">
                <div class="row">
                    <div class="col-12">
                      <b-form ref="step1">
                        <div class="row">
                            <b-form-group class="col-6">
                                <label for="input-2">Name <span style="color: red">*</span></label>
                                <b-form-input id="input-2" placeholder="Enter Title" v-model="form.name" :class="{
                                'is-invalid': submitted && $v.form.name.$error,
                                }"></b-form-input>
                                <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                                Name is required.
                                </div>
                            </b-form-group>
                            <b-form-group class="col-6">
                                <label for="input-2">Username <span style="color: red">*</span></label>
                                <b-form-input id="input-2" placeholder="Enter Title" v-model="form.username" :class="{
                                'is-invalid': submitted && $v.form.username.$error,
                                }"></b-form-input>
                                <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">
                                Username is required.
                                </div>
                            </b-form-group>
                            <b-form-group class="col-6" label-for="input-multi">
                                <div class="mb-1" style="line-height: 1;">
                                    <label for="input-multi" style="margin-top: 5px">Country <span style="color: red">*</span></label>
                                </div>
                                <multiselect @search-change="fetchAllCountry" id="input-multi"
                                v-model="form.country" :options="allCountry" track-by="id"
                                    label="name" placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.email.$error,
                                }">
                                </multiselect>
                                <span slot="noOptions">
                                    Type here to search
                                </span>
                                <div v-if="submitted && !$v.form.country.required" class="invalid-feedback">
                                    Country is required.
                                </div>
                            </b-form-group>
                            <b-form-group class="col-6">
                                <label for="input-2">Email <span style="color: red">*</span></label>
                                <b-form-input id="input-2" placeholder="Enter Title" v-model="form.email" :class="{
                                'is-invalid': submitted && $v.form.email.$error,
                                }"></b-form-input>
                                <div v-if="submitted && !$v.form.email.required" class="invalid-feedback">
                                    Email is required.
                                </div>
                                <div v-if="submitted && !$v.form.email.email" class="error">
                                    <span>Please Enter Valid Email.</span>
                                </div>
                            </b-form-group>
                            <template v-if="(this.$route.name == 'edit-digiMr')">
                                <b-form-group class="col-6">
                                    <label for="input-2">Password <span style="color: red">*(Enter Password if change needed.)</span></label>
                                    <b-form-input id="input-2" placeholder="Enter Password" v-model = form.password></b-form-input>
                                    <div v-if="form.password != '' && !$v.form.password.valid" class="invalid-feedback">
                                        Password is required.
                                    </div>
                                </b-form-group>
                            </template>
                            <template v-else>
                                <b-form-group class="col-6">
                                    <label for="input-2">Password <span style="color: red">*(Enter Password if change needed.)</span></label>
                                    <b-form-input id="input-2" placeholder="Enter Password" v-model = form.password :class="{
                                    'is-invalid': submitted && $v.form.password.$error,
                                    }"></b-form-input>
                                    <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">
                                        Password is required.
                                    </div>
                                </b-form-group>
                            </template>
                            <b-form-group class="col-12">
                                <b-form-checkbox v-model="form.field_mr">Is Field Mr</b-form-checkbox>
                            </b-form-group>
                            <b-form-group class="col-6" v-if="form.field_mr">
                                <label for="reporting_url">Dashboard Reporting Url</label>
                                <b-form-input id="reporting_url" placeholder="Enter Url" v-model="form.reporting_url"></b-form-input>
                            </b-form-group>
                            <b-form-group class="col-6"  v-if="form.field_mr">
                                <label for="reporting_url_date">Url Expiry date</label>
                                <b-form-input id="reporting_url_date" type="date" placeholder="Enter Date" v-model="form.reporting_url_expire_date"></b-form-input>
                            </b-form-group>
                            <b-form-group class="col-12">
                                <b-form-checkbox v-model="form.is_active">Is Active</b-form-checkbox>
                            </b-form-group>
                        </div>
                      </b-form>
                    </div>
                  </div>
                <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData">
                    <span v-if="this.$route.name == 'add-digiMr'">Save Data</span>
                    <span v-else>Update Data</span>
                </b-button>
            </b-form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../mixins/request";
  import DigiMrMixin from "../../../mixins/ModuleJs/digimr";
  
  import Multiselect from "vue-multiselect";
  import { required, email } from "vuelidate/lib/validators";
  
  export default {
    data() {
      return {
        finalModel: {},
        tabOne: false,
        tabThree:false,
        submitted: false,
        interval: 0,
        slide: 0,
        sliding: null,
        title1: "Add Digi MR",
        title2: "Edit Digi MR",
        fields: [
          {
            key: "link_id",
            label: "Case Link ID",
          },
          {
            key: "images",
            label: "Item Image",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        items: [
          {
            text: "Back",
            href: "/digiMr",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    mixins: [MixinRequest, DigiMrMixin],
    validations: {
      form: {
        name: { required },
        username: { required },
        email: { required, email },
        password: { required },
        country: { required },
      },
    },
    components: {
      Layout,
      PageHeader,
      Multiselect,
    },
    methods: {
      
    },
  };
  </script>
  