var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-digiMr')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row",attrs:{"id":"case-carousel"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"forum",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();this.$route.name == 'add-digiMr' ? 'Save Data' : 'Update Data'}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-form',{ref:"step1"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
                            },attrs:{"id":"input-2","placeholder":"Enter Title"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Username "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.form.username.$error,
                            },attrs:{"id":"input-2","placeholder":"Enter Title"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),(_vm.submitted && !_vm.$v.form.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label-for":"input-multi"}},[_c('div',{staticClass:"mb-1",staticStyle:{"line-height":"1"}},[_c('label',{staticStyle:{"margin-top":"5px"},attrs:{"for":"input-multi"}},[_vm._v("Country "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])]),_c('multiselect',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.form.email.$error,
                            },attrs:{"id":"input-multi","options":_vm.allCountry,"track-by":"id","label":"name","placeholder":"Type here to search"},on:{"search-change":_vm.fetchAllCountry},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")]),(_vm.submitted && !_vm.$v.form.country.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Country is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Email "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                            'is-invalid': _vm.submitted && _vm.$v.form.email.$error,
                            },attrs:{"id":"input-2","placeholder":"Enter Title"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.submitted && !_vm.$v.form.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Email is required. ")]):_vm._e(),(_vm.submitted && !_vm.$v.form.email.email)?_c('div',{staticClass:"error"},[_c('span',[_vm._v("Please Enter Valid Email.")])]):_vm._e()],1),((this.$route.name == 'edit-digiMr'))?[_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Password "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*(Enter Password if change needed.)")])]),_c('b-form-input',{attrs:{"id":"input-2","placeholder":"Enter Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(_vm.form.password != '' && !_vm.$v.form.password.valid)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()],1)]:[_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Password "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*(Enter Password if change needed.)")])]),_c('b-form-input',{class:{
                                'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                                },attrs:{"id":"input-2","placeholder":"Enter Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(_vm.submitted && !_vm.$v.form.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()],1)],_c('b-form-group',{staticClass:"col-12"},[_c('b-form-checkbox',{model:{value:(_vm.form.field_mr),callback:function ($$v) {_vm.$set(_vm.form, "field_mr", $$v)},expression:"form.field_mr"}},[_vm._v("Is Field Mr")])],1),(_vm.form.field_mr)?_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"reporting_url"}},[_vm._v("Dashboard Reporting Url")]),_c('b-form-input',{attrs:{"id":"reporting_url","placeholder":"Enter Url"},model:{value:(_vm.form.reporting_url),callback:function ($$v) {_vm.$set(_vm.form, "reporting_url", $$v)},expression:"form.reporting_url"}})],1):_vm._e(),(_vm.form.field_mr)?_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"reporting_url_date"}},[_vm._v("Url Expiry date")]),_c('b-form-input',{attrs:{"id":"reporting_url_date","type":"date","placeholder":"Enter Date"},model:{value:(_vm.form.reporting_url_expire_date),callback:function ($$v) {_vm.$set(_vm.form, "reporting_url_expire_date", $$v)},expression:"form.reporting_url_expire_date"}})],1):_vm._e(),_c('b-form-group',{staticClass:"col-12"},[_c('b-form-checkbox',{model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}},[_vm._v("Is Active")])],1)],2)])],1)]),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-digiMr')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }